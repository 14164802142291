<template>
  <div>
    <!-- <Navbar /> -->
    <div class="text-center fn24-weight7 mb-4" style="color: #1a347f">
      CE CREDIT
    </div>
    <v-tabs v-model="tab" active-class="active" centered fixed-tabs>
      <v-tab v-for="(item, index) in items" :key="index" width="160">
        <span style="color: #1a347f" class="fn12-weight7 text-capitalize mt-3">
          {{ item.name }}
        </span>
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(item, index) in items" :key="index">
        <div v-for="(object, index) in item.objects" :key="index" class="my-2">
          <v-card
            elevation="2"
            class="rounded-lg mx-auto"
            @click="goToCredit(item.name)"
            width="340"
            max-width="374"
            max-height="151"
            height="151"
          >
            <v-row class="mt-2">
              <v-col cols="4" class="py-0 pr-0">
                <v-img
                  :src="object.image"
                  width="128"
                  max-width="374"
                  max-height="151"
                  height="151"
                  class="rounded-l-lg"
                />
              </v-col>
              <v-col cols="8" class="py-0 pb-0">
                <!-- <div> -->
                <div class="mx-3 fn14-weight7 mt-1">
                  {{ object.name }}
                </div>

                <div class="mx-3 my-0 fn14 pa-0">
                  {{ object.content | limitText }}
                </div>

                <v-row class="mx-2 mt-1" align="center" justify="center">
                  <v-col cols="6" class="pa-0">
                    <div>
                      <span
                        style="
                          font-size: 12px;
                          background: #dfe2e9;
                          color: #6e798c;
                          font-weight: 600;
                        "
                        class="rounded-lg pa-1 ml-2 fn11"
                      >
                        {{ object.time }}
                      </span>
                    </div>
                  </v-col>
                  <v-col cols="2" class="pa-0">
                    <v-img src="@/assets/icon/CE.png" width="15" height="15" />
                  </v-col>
                  <v-col cols="4" class="pa-0">
                    <span
                      style="font-size: 10px; color: #1a347f; font-weight: 600"
                    >
                      {{ object.points }}
                    </span>
                  </v-col>
                </v-row>
                <!-- </div> -->
              </v-col>
            </v-row>
          </v-card>
        </div>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import draggable from "vuedraggable";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { Decode, Encode } from "@/services";
// import Navbar from "@/components/nav";
export default {
  filters: {
    limitText(val) {
      return val.length > 50 ? val.slice(0, 50) + "..." : val;
    },
  },
  components: {
    draggable,
    Loading,
    // Navbar,
  },
  data() {
    return {
      tab: null,
      items: [
        {
          name: "Available",
          objects: [
            {
              name: "Feline Atopic Syndrome (FAS) : อาการและแนวทางการวินิจฉัย",
              image: require("@/assets/CE/pic3.jpg"),
              content:
                "รู้จักแนวทางการวินิจฉัยและอาการเบื้องต้น ของโรค FASโรคกลุ่มภูมิแพ้ในแมวที่แตกต่าง จากในสุนัข",
              time: "9 Days Left",
              points: "100 Points",
            },
            {
              name: "Feline Atopic Syndrome (FAS) : อัพเดตแนวทางการรักษา",
              image: require("@/assets/CE/pic4.jpg"),
              content:
                "มาเรียนรู้แนวทางการรักษา FAS ฉบับอัพเดตตามข้อมูลปี ค.ศ. 2021",
              time: "40 Days Left",
              points: "3 Points",
            },
          ],
        },
        {
          name: "Expired",
        },
      ],
      search: "",
      sortIndex: 0,
      user: "",
      loading: false,
    };
  },
  created() {
    this.user = JSON.parse(
      Decode.decode(localStorage.getItem("userBiData"))
    );
    this.getAllbrand();
  },
  methods: {
    async DragItems(val) {
      this.loading = true;
      console.log("this.itemsssssss", this.items);
      console.log("beforeAPI", val);
      var user = JSON.parse(
        Decode.decode(localStorage.getItem("userBiData"))
      );
      const auth = {
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MjcsImVtYWlsIjoiaXR0aXd1dC53b25nM0BnbWFpbC5jb20iLCJwYXNzd29yZCI6IiQyYiQxMCRqU0UvY3RsSGhmTmJYemV5QXAvUHhPc3NOSS9HNG5NTHNsNlNBanFySzlVYzFMQmhDazh1UyIsImZpcnN0TmFtZSI6InNkZiIsImxhc3ROYW1lIjoic2RmIiwidGVsQ29kZSI6Iis2NiIsInRlbE51bWJlciI6IjIzNDU0NSIsImxpbmUiOiJkZnMiLCJnZW5kZXIiOiIiLCJhZ2UiOjEyLCJsaWNlbnNlTm8iOiIiLCJzY2hvb2wiOiIiLCJleHBlcmllbmNlIjoiZnNkZiIsImFsbGVyZ3kiOiJzZGYiLCJtaW5GZWUiOjEwLCJtYXhGZWUiOjIwLCJwcm92aW5jZSI6IuC4quC4oeC4uOC4l-C4o-C4m-C4o-C4suC4geC4suC4oyIsInVzZXJSZXF1ZXN0Ijoic2RmIiwiaWRDYXJkSW1nIjpudWxsLCJlZHVjYXRpb25JbWciOm51bGwsIndvcmtpbmdBcmVhIjoiamtqIiwibGF0IjpudWxsLCJsbmciOm51bGwsInNjaGVkdWxlIjoiWzIsM10iLCJyb2xlIjoiVVNFUiIsImlzUHJvIjpmYWxzZSwiaXNQdWJsaWMiOmZhbHNlLCJhY3RpdmVGbGFnIjp0cnVlLCJjcmVhdGVkQXQiOiIyMDIxLTExLTExVDA1OjQ3OjM4LjAwMFoiLCJ1cGRhdGVkQXQiOiIyMDIxLTExLTExVDExOjUxOjM2LjAwMFoiLCJqb2JJZCI6NCwiZWR1Y2F0aW9uSWQiOjEsImxpY2Vuc2VJZCI6MSwic2VydmljZVR5cGVJZCI6MSwiZmVlQ3VycmVuY3lJZCI6MSwiZmVlRnJlcXVlbmN5SWQiOjIsImlhdCI6MTYzNjkwODA0Mn0.4ctskWtU_WO5pDZMDa6M8vxLFCJZPaRCNxhNMiwl0-E`,
        },
      };
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/brands/updateIndex`,
        val,
        auth
      );
      console.log("afterAPI", response);

      this.items = response.data.data;
      // for (let i in this.items) {
      //   this.items[i].count = parseInt(i) + 1;
      // }
      this.loading = false;
    },
    async getAllbrand() {
      const auth = {
        headers: { Authorization: `Bearer ${this.user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/brands`,
        auth
        // 'http://localhost:8081/types/'
      );
      console.log("getAllbrandpe", response.data);
      this.items = response.data.data;
      for (let i in this.items) {
        this.items[i].count = parseInt(i) + 1;
        //console.log(this.list);
      }
      // console.log("itemBefore", this.items);
      // console.log('itemAfter',this.items);
    },
    UpdateBrand(val) {
      localStorage.setItem("Branddata", Encode.encode(val));
      this.$router.push("EditBrand");
    },
    ViewBrand(val) {
      localStorage.setItem("Branddata", Encode.encode(val));
      this.$router.push("ViewBrand");
    },
    async DeleteBrand(val) {
      this.$swal({
        text: "คุณต้องการลบข้อมูลใช่ หรือไม่ ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ต้องการลบข้อมูล",
      }).then(async (result) => {
        const auth = {
          headers: { Authorization: `Bearer ${this.user.token}` },
        };
        if (result.isConfirmed) {
          const response = await this.axios.delete(
            `${process.env.VUE_APP_API}/brands/` + val.id,
            auth
          );
          console.log(response);

          this.$swal.fire({
            icon: "success",
            text: "ลบข้อมูลสำเร็จ",
            showConfirmButton: false,
            timer: 1000,
          });
          // await location.reload();
          this.getAllbrand();
        }
      });
    },
    goToCredit(val) {
      if (val === "Available") {
        this.$router.push("CreditCE");
      }
    },
  },
};
</script>

<style scoped>
.active {
  color: rgba(255, 255, 255, 0) !important;
  background-color: rgba(255, 255, 255, 0) !important;
}
</style>
